import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import logoTec from "../images/logotec.png"

import $ from '../jquery/jquery.slim' // importing this worked like a charm 
// Collapse Navbar
if (typeof window !== 'undefined') {
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll < 0) {
      $('#site-navbar').css('background', 'transparent');
    } else {
      $('#site-navbar').css('background', 'rgb(0, 0, 0)');
    }
  });
}
const CustomNavbar = ({ pageInfo, data }) => {
  return (
    <>
      <Navbar variant="dark"  expand="lg" className="home" id="site-navbar" >
        {/* <Container> */}
        <a href="https://tec.mx" target="_blank" rel="noopener noreferrer"><img src={logoTec} alt="logotec" style={{ maxWidth: `120px` }} /></a>
        <Link to="/" className="link-no-style">
          <Navbar.Brand as="span">&nbsp; OpenResearchLab</Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
      
      </Nav>
          <Nav className="ml-auto" activeKey={pageInfo && pageInfo.pageName}>
            <Nav.Link href="/">Inicio</Nav.Link>
            <Nav.Link href="/acercade">El proyecto</Nav.Link>
            <Nav.Link href="/openresearch">OpenResearchLab</Nav.Link>
            <Nav.Link href="/investigacion">Investigación</Nav.Link>
            <Nav.Link href="/contacto">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  )
}


export default CustomNavbar
