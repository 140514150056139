/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Carousel, Image, Container, Row, Col } from "react-bootstrap"
import Navbar from "./navBarHome"
import logoNovus from "../images/novus.png"
import { Link } from "gatsby"

import primera from "../images/Portada_Inicio_Nueva.png"
import segunda from "../images/OpenresearcLab.png"
import tercera from "../images/Investigacion.png"
import cuarta from "../images/el_proyecto.png"
// Smooth scrolling using jQuery easing

var timer = setTimeout(() => {
  window.location.reload();
}, 3000);
clearTimeout(timer);


const LayoutHome = ({ children, pageInfo }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQueryHome {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Container fluid className="px-0" style={{ padding: 0 }}>
          <Navbar pageInfo={pageInfo} />
          <Row className="justify-content-center">
            <Col>
              <Carousel fade style={{ background: `black` }}>

                <Carousel.Item>
                  <Image fluid
                    className="d-block vw-100"
                    src={primera}
                    alt="Second slide"
                  />
                  <Carousel.Caption>
                  </Carousel.Caption>

                </Carousel.Item >
                <Carousel.Item interval={2500}>
                  <Link to="/acercade" className="link-no-style">
                    <Image fluid
                      className="d-block vw-100 "
                      src={cuarta}
                      alt="Second slide"
                    />
                  </Link>
                </Carousel.Item >
                <Carousel.Item interval={2500}>
                  <Link to="/openresearch" className="link-no-style">
                    <picture>
                      <source srcset={segunda} media="(min-width: 1400px)" />
                      <source srcset={segunda} media="(min-width: 768px)" />
                      <source srcset={segunda} media="(min-width: 576px)" />
                      <img srcset={segunda} alt="responsive image" class="vw-100 img-fluid" />
                    </picture>
                  </Link>
                </Carousel.Item >
                <Carousel.Item interval={2500}>
                  <Link to="/investigacion" className="link-no-style">
                    <Image fluid
                      className="d-block vw-100 "
                      src={tercera}
                      alt="Second slide"
                    />
                  </Link>
                </Carousel.Item >
              </Carousel>
            </Col>
            <div class="containerArrow">
              <Link to="#about" >
                <div class="chevron"></div>
                <div class="chevron"></div>
                <div class="chevron"></div>
              </Link>
            </div>
          </Row>
          <Row noGutters>
            <Col>
              <Container className="mt-5 body-container">
                <main>{children}</main>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container fluid className="px-0">
          <Row>
            <Col className="footer-col">
              <footer>
              <span>
                <img src={logoNovus} alt="logoNovus" style={{ maxHeight: `75px` }} />
                  <br />
                   {new Date().getFullYear()}, <a href="https://novus.itesm.mx/" rel="noopener noreferrer" className="link-no-style" style={{ color: `#fff` }} target="_blank"> Novus </a> - OpenResearchLab <br />
                  <img src="https://static.wixstatic.com/media/446d2f_82746ead81a447a4b94ec6572070b933~mv2.png/v1/fill/w_114,h_38,al_c,lg_1,q_85/cc.webp" alt="cc.png" style={{ width: `91px`, height: `30px` }}></img>
                  <p>Este obra está bajo una <a href="http://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank" rel="noreferrer noopener">licencia de Creative Commons Reconocimiento-NoComercial-CompartirIgual 4.0 Internacional</a></p>

                </span>
              </footer>
            </Col>
          </Row>
        </Container>
      </>
    )}
  />
)

export default LayoutHome
