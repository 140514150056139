import React from "react"
import { Container, Row, Col, Section } from "react-bootstrap"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import LayoutHome from "../components/layout-home"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <LayoutHome pageInfo={{ pageName: "OpenResearchLab" }}>
    <SEO title="OpenResearchLab" keywords={[`gatsby`, `react`, `bootstrap`]} />
    <Container className="text-center">
      <section id="about" className="about-section">
        <Row>
          <Col><br /><br />
            <h2>Descripción del proyecto</h2>
            <p className="text-justify">
            El proyecto OpenResearchLab surge en medio de una situación de contingencia a nivel mundial derivada de la pandemia por COVID-19. En este complejo escenario el laboratorio hace uso de inteligencia artificial y robótica para favorecer el desarrollo de competencias de investigación, de alfabetización académica y de razonamiento para la complejidad. Aunque el proyecto nace en el marco de NOVUS del Tecnológico de Monterrey, se busca su transferibilidad a otros entornos universitarios, con la intención de impactar positivamente en los ámbitos educativo, económico y social de manera global.
            </p>
          </Col>
        </Row>
        <br /><br /><br /><br />
      </section>
    </Container>
  </LayoutHome>
)

export const query = graphql`
  query {
      fileName: file(relativePath: {eq: "demo-image-01.jpg"  }) {
      childImageSharp {
      fluid(maxWidth: 300) {
      ...GatsbyImageSharpFluid
    }
    }
  }
}
`

export default IndexPage

